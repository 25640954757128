// Import Element Theme
@import '@simpl/element-theme/src/theme';

// Import Element Components
@import '@simpl/element-ng/simpl-element-ng';

/* back_drop */
.fade-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 9999; 
    display: flex;
    justify-content: center;
    align-items: center;
  }